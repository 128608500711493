.animation {
  width: 100%;
  height: 100%;

  --fx-animation-speed: 420ms;
  --fx-animation-timing-function: ease;
  --fx-will-change: auto;

  --fx-size: 1.69;

  --fx-color-before: #ffffff;
  --fx-color-after: #000000;
}

.fx-animation {
  transition-property: all;
  transition-duration: var(--fx-animation-speed);
  transition-timing-function: var(--fx-animation-timing-function);
  will-change: var(--fx-will-change);

  &:hover {
    transition-property: all;
    transition-duration: var(--fx-animation-speed);
    transition-timing-function: var(--fx-animation-timing-function);
  }
}

.fx-scale {
  position: relative;
  z-index: 10;
  transform: scale(1);

  &.fx-active {
    z-index: 20;
    transform: scale(var(--fx-size));
  }
}

.fx-bg-color {
  background-color: var(--fx-color-before);

  &.fx-active {
    background-color: var(--fx-color-after);
  }
}

// removes animation for users who prefer reduced motion
@media (prefers-reduced-motion: reduce) {
  .fx-animation {
    transition: none;
  }
}
